@use 'sass:math';
@import 'styles/_helpers';

.slides {
    $this: &;
    $control-size: 38px;
    $padding-top: calc((#{$margin} * 2) + #{$control-size});
    position: fixed;
    z-index: $level-3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-background;

    &__wrapper {
        display: flex;
        min-width: 100%;
        height: 100%;
        will-change: transform;
        transition: transform 500ms $ease-in-out-quint--custom;
    }

    &__title {
        @include font-size(title);
        margin-bottom: 1.4rem;

        &--xl {
            @include font-size(xl);
        }
    }

    &__text {
        @include font-size(sub-title);

        p:not(:last-child) {
            margin-bottom: 1em * $base-line-height;
        }
    }

    &__image {
        &--fullscreen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            // background-color: var(--background, #{$color-dark});
        }

        &--split {
            border-radius: $border-radius;
            margin-bottom: $margin;

            @include min-width(md) {
                marign: 0;
                order: 2;
                max-height: calc(100vh - (#{$margin} * 2) - 40px);
            }
        }
    }

    &__image-wrapper {
        &--small {
            position: relative;
            aspect-ratio: var(--aspect);
            max-height: 100%;
            margin: 0 auto;
        }
    }

    &__controls {
        position: fixed;
        display: flex;
        z-index: 4;
        justify-content: space-between;
        left: 0;
        width: 100%;
        top: 0;
        padding: $margin;
    }

    &__control {
        transition: opacity 550ms $ease-in-out-quint--custom;
        margin-right: 4px;

        &[disabled] {
            opacity: 0.1;
        }

        &--close {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &__slide {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex-shrink: 0;
        padding: $padding-top $margin $margin;

        &--headline {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: $padding-top $margin;
        }

        &--split {
            display: flex;
            flex-direction: column;

            @include min-width(md) {
                display: grid;
                align-items: start;
                grid-template-columns: 6fr 6fr;
                grid-gap: $gutter;
            }
        }

        &--text-content {
            display: grid;
            grid-template-columns: 10fr 2fr;
            justify-content: start;

            #{$this}__text {
                margin-bottom: auto;
            }
        }

        &--image-large {
            @include min-width(lg) {
                display: grid;
                align-items: start;
                grid-template-columns: 4fr 8fr;
                grid-gap: $gutter;
            }
        }

        &--image-small {
            @include min-width(lg) {
                grid-template-columns: 8fr 4fr;
            }

            #{$this}__content {
                @include min-width(xl) {
                    max-width: percentage(math.div(6, 8));
                }
            }
        }
    }

    &--fluid {
        position: relative;
        height: calc(100vh - 168px);
        border-radius: $border-radius;

        @include min-width(md) {
            aspect-ratio: 16/9;
            height: auto;
        }

        #{$this}__slide {
            padding: $margin;
        }

        #{$this}__controls {
            position: absolute;
            top: auto;
            bottom: 0;
            padding: 0 0 $spacing--small;
            justify-content: center;
        }
        // height: calc(100vh - 240px);
    }
}
