@import 'styles/_helpers';

.talking-cards {
    $this: &;
    $large-breakpoint: map-get($breakpoints, lg);

    --card-size: 12em;
    --active-offset: -5.2em;
    position: fixed;
    z-index: $level-3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $color-background;

    @include min-width(md) {
        --card-size: 13em;
        --active-offset: -6.2em;
    }

    @media screen and (min-width: $large-breakpoint) and (max-height: 1000px) {
        --card-size: 12em;
        --active-offset: -5.2em;
    }

    &--instructions {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: $margin;

        @include min-width(md) {
            padding-bottom: $spacing--large;
        }

        #{$this}__wrapper {
            height: auto;
        }
    }

    &__wrapper {
        min-width: 100%;
        height: 100%;
    }

    &__header {
        text-align: center;
        margin: $spacing 0 0;
        padding-top: $spacing--xsmall;
    }

    &__title {
        @include font-size(xl);
        margin: 0;

        &--small {
            @include font-size(title-large);
            text-align: center;
        }
    }

    &__instructions {
        position: relative;
        border-radius: $border-radius--large;
        max-width: 44rem;
        margin: $spacing--large $margin;
        padding: $spacing--large $margin $spacing;
        background-color: color(primary, light);

        @include min-width(md) {
            margin: $spacing--large auto;
            padding: $spacing--large 6rem 3rem;
        }

        &--more {
            animation: more-instructions-enter 600ms $ease-in-out-quint--custom both;
        }

        &--default {
            animation: instructions-enter 600ms $ease-in-out-quint--custom both;
        }
    }

    &__numbers {
        @include font-size(title, true);
        line-height: 1.3;
        margin: $spacing--large 0;
    }

    &__details {
        @include font-size(sub-title, true);
        line-height: 1.35;
        margin: 0 0 $spacing--large;
    }

    &__text {
        @include font-size(sub-title, true);
        line-height: 1.35;
        margin: $spacing--large 0;

        @include min-width(md) {
            margin: $spacing 0;
        }

        p:not(:last-child) {
            margin-bottom: 1em * $base-line-height;
        }

        h2 {
            margin-bottom: 0.15em;
        }
    }

    &__actions {
        display: grid;
        gap: 8px;
        margin: 0 auto;
        max-width: 16rem;
    }

    &__action {
        &--primary {
            #{$this} & {
                @include font(base, bold);
                min-width: 8rem;

                @include min-width(md) {
                    min-width: 10rem;
                }
            }
        }

        &--muted {
            #{$this} & {
                background-color: color(primary, light);

                &:hover,
                &:focus:focus-visible {
                    background-color: color(primary);
                }
            }
        }
    }

    &__illustration {
        margin: 0 auto $spacing--small;
        max-width: 4rem;

        @include min-width(md) {
            max-width: 6.6rem;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 860px) {
            display: none;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 1000px) {
            max-width: 5rem;
        }

        &--entry {
            display: block;
            max-width: min(50%, 8rem);
            margin: auto;
        }
    }

    &__logo {
        margin: 0 auto $spacing;
        max-height: 2.8rem;

        @include min-width(md) {
            max-height: 4.8rem;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 850px) {
            display: none;
        }

        @media screen and (min-width: $large-breakpoint) and (max-height: 1000px) {
            max-height: 3.4rem;
        }
    }

    &__controls {
        position: fixed;
        display: flex;
        z-index: $level-4;
        justify-content: space-between;
        left: 0;
        width: 100%;
        top: 0;
        padding: $margin;

        &--footer {
            @include font-size(sub-title, true);
            flex-direction: column;
            align-items: center;
            top: auto;
            bottom: 0;

            @include min-width(md) {
                padding: $spacing--small;
            }
        }
    }

    &__disclaimer {
        @include font-size(small);
        color: $color-muted;
        margin-top: $spacing;
    }

    &__control {
        transition: opacity 550ms $ease-in-out-quint--custom;
        margin-right: 4px;

        &[disabled] {
            opacity: 0.1;
        }

        &--close {
            margin-left: auto;
            margin-right: 0;
        }

        &--print {
            margin-left: 0;
            margin-right: auto;
        }

        &--back {
            position: absolute;
            top: $spacing;
            left: $spacing;
        }
    }

    &__cards {
        --transition: 420ms;
        @include font-size(sub-title, true);
        display: flex;
        padding-top: $spacing--large;
        padding-bottom: 50%;

        @include min-width(md) {
            padding-top: $spacing;
            gap: $gutter;
        }

        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &:before,
        &:after {
            content: '';
            flex-shrink: 0;
            width: calc(50vw - var(--card-size) / 2 - #{$gutter});
        }

        &--entering {
            #{$this}__card {
                animation: card-enter 500ms $ease-out-cubic both;
                @include animation-delay(20, 100ms);
            }
        }

        &--active {
            --transition: 1400ms;
            // overflow: hidden;

            #{$this}__card {
                animation: none;

                &:hover {
                    background-color: color(primary);
                }

                &:not(:first-child) {
                    margin-left: var(--active-offset);
                }

                &:not(:last-child) {
                    margin-right: var(--active-offset);
                }

                &:not(#{$this}__card--active) {
                    color: transparent;
                }
            }
        }
    }

    &__card {
        cursor: pointer;
        flex-shrink: 0;
        display: flex;
        text-align: center;
        flex-direction: column;
        padding: 1em;
        border-radius: $border-radius;
        width: var(--card-size);
        aspect-ratio: 3/4;
        background-color: color(primary);
        transform-origin: center 110%;
        transition:
            transform var(--transition, 420ms) $ease-out-expo,
            box-shadow 500ms $ease-out-quart,
            background-color 500ms $ease-out-quart,
            margin 440ms $ease-out-expo;
        z-index: var(--z-index, 2);

        will-change: transform, background-color, margin, box-shadow, z-index;

        box-shadow: 0 2px 20px rgba(black, 0.05);

        &:hover {
            background-color: color(primary, dark);
        }

        &--focus {
            #{$this}__cards:not(#{$this}__cards--active) & {
                z-index: 201;
                box-shadow: 0 5px 35px rgba(black, 0.15);
            }
        }

        &--active {
            z-index: 202;
            box-shadow: 0 5px 35px rgba(black, 0.15);
        }

        &--claim {
            #{$this}__sub-title {
                p:before {
                    content: '«';
                }
                p:after {
                    content: '»';
                }
            }
        }
        &--claim-alt {
            #{$this}__sub-title {
                p:before {
                    content: '«';
                }
                p:after {
                    content: '».';
                }
            }
        }
    }

    &__sub-title {
        @include font(base, normal);
        font-size: inherit;
        line-height: 1.3;
        margin: auto 0;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto;
        align-self: end;
        bottom: 0;
    }

    &__task {
        @include font-size(base);
        margin-bottom: $spacing--small;
    }

    &__path {
        $width: 1200px;
        $width--md: 140vw;
        $width--active: 100vw;
        visibility: hidden;
        position: fixed;
        display: block;
        left: 50%;
        width: $width;
        max-width: none;
        transform: translateX(-50%);

        @include min-width(md) {
            width: $width--md;
        }

        &--active {
            @include min-width(md) {
                width: $width--active;
            }
        }
    }

    &__entry {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 $spacing--small $spacing--small;
        border-radius: $border-radius--large;
        background-color: color(primary, light);
        aspect-ratio: 1/1;

        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        &:hover,
        &:focus:focus-visible {
            background-color: color(primary);

            #{$this}__tag {
                background-color: color(primary, light);
            }
        }
    }

    &__tag {
        @include font-size(small);
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }

    &__entry-title {
        @include font(base, bold);
        @include font-size(sub-title);
        margin-top: $spacing--xsmall;
    }

    &--active {
        #{$this}__cards {
            overflow: hidden;
        }
    }

    &--iframe {
        #{$this}__controls--footer {
            bottom: auto;
            top: clamp(50vh + 200px, 62vw, 86vh);
        }
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes more-instructions-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--large});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--large});
    }
}

@keyframes instructions-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--large});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--large});
    }
}

@keyframes card-enter {
    from {
        box-shadow: none;
        clip-path: inset(100% 100% 0 0 round #{$border-radius});
    }

    to {
        clip-path: inset(-50px -50px -50px -50px round #{$border-radius});
    }
}
