@import 'styles/_helpers';

.nav-list {
    $this: &;
    $circle-size-mobile: 44px;
    $bottom-spacing: 10px; // Mobile Safari;

    @include min-width(md) {
        position: sticky;
        top: $margin;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $margin $spacing--xsmall;

        &--expandable {
            position: fixed;
            z-index: $level-2;
            left: 0;
            bottom: 0;
            width: 100%;
            height: $header-height;
            box-shadow: 0 0 20px rgba(black, 0.1);
            background-color: $color-background;
        }

        @include min-width(md) {
            display: block;
            position: absolute;
            z-index: auto;
            bottom: auto;
            height: auto;
            box-shadow: none;
            width: calc(var(--column-width) - #{$margin});
            left: calc(-1 * var(--column-width));
            padding: 0;
            background-color: $color-background;
        }
    }

    &__list {
        display: none;
        flex-direction: column;
        background-color: $color-background;

        &--expanded {
            @include max-width(md) {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                left: 0;
                bottom: $header-height;
                background-color: $color-background;
                padding-top: $margin;
                box-shadow: 0px -10px 10px rgba(black, 0.02);

                animation: nav-list-expand 500ms $ease-out-quart both;
            }
        }

        @include min-width(md) {
            display: flex;
            align-items: flex-start;
            height: calc(100vh - 17rem);
            width: calc(100% + #{$margin});
            padding-right: $margin;
            //overflow: auto;
        }

        &--overflow {
            @include min-width(md) {
                /* https://pqina.nl/blog/fade-out-overflow-using-css-mask-image/ */
                @include overflow-scroll-y;
                overflow-x: clip;
                $mask: linear-gradient(
                    to bottom,
                    black,
                    black calc(100% - var(--mask-height)),
                    transparent
                );
                --mask-height: 32px;
                padding-bottom: var(--mask-height);
                padding-right: 20px;
                mask-image:
                    #{$mask},
                    linear-gradient(black, black);
                mask-size:
                    100% 100%,
                    0px 100%;
                mask-position:
                    0 0,
                    100% 0;
                mask-repeat: no-repeat, no-repeat;
            }
        }
    }

    &__back {
        @include font-size(small);
        @include font(base, bold);
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        animation: item-enter 550ms $ease-in-out-quint--custom both;

        &:not(:last-child) {
            @include min-width(md) {
                margin-bottom: $spacing;
            }
        }

        &:hover,
        &:focus:focus-visible {
            #{$this}__back-circle {
                background-color: color(primary, dark);
            }
        }
    }

    &__back-label {
        @include text-overflow;
        display: none;

        @include min-width(md) {
            display: block;
        }
    }

    &__back-circle {
        position: relative;
        z-index: 2;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: $circle-size-mobile;
        height: $circle-size-mobile;
        overflow: hidden;
        padding: 0;
        border-radius: $border-radius--small;
        background-color: color(primary);

        @include min-width(md) {
            margin-right: $spacing--xsmall;
            width: var(--circle-size);
            height: var(--circle-size);
        }
    }

    &__active-label {
        margin-right: 12px;
    }

    &__toggle {
        height: $circle-size-mobile;
        z-index: $level-2;
        display: flex;
        align-items: center;
        width: auto;
        padding: 0 20px;
        border-radius: $border-radius--small;
        background-color: color(neutral, light);

        @include min-width(md) {
            display: none;
        }
    }

    &__item {
        @include text-overflow;
        color: $color-muted;
        flex-shrink: 0;
        margin-bottom: 0.3em;

        animation: item-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        @include min-width(md) {
            @include font-size(small);
            color: $color-text;
            max-width: 105%;
            padding: 8px 12px;
            border-radius: $border-radius--small;
            background-color: color(neutral, xlight);
        }

        @include min-width(xxl) {
            max-width: 112%;
        }

        $large-breakpoint: map-get($breakpoints, lg);

        @media screen and (min-width: $large-breakpoint) and (max-height: $height-breakpoint) {
            max-width: 95%;
        }

        &:hover,
        &:focus {
            background-color: color(neutral, light);
        }

        &--inline {
            @include min-width(md) {
                padding: 15px 23px;
                border-radius: $border-radius;
                background-color: color(primary, xlight);
            }

            @include min-width(xxl) {
                max-width: 112%;
            }

            &:hover,
            &:focus {
                background-color: color(primary);
            }
        }

        &--icon {
            display: flex;
            gap: 0.5em;
            align-items: center;
        }

        @include max-width(md) {
            @include font-size(base);
            padding: 14px 34px;
            margin: 0 0 10px;
            color: $color-text;
            border-radius: $border-radius;
            background-color: color(neutral, light);

            animation: nav-list-item-expand 500ms $ease-out-quart both;
            @include animation-delay(10, 50ms);
        }

        &:hover,
        &:focus,
        &--active,
        &--current {
            color: $color-text;
        }

        &--current,
        &--active {
            background-color: color(primary);

            &:hover,
            &:focus {
                background-color: color(primary);
            }
        }
    }

    &__icon {
        margin-right: -0.2em;
    }

    &__topics {
        display: grid;
        justify-items: start;
    }

    &--hidden-mobile {
        @include max-width(md) {
            display: none;
        }
    }
}

@keyframes nav-list-expand {
    from {
        transform: translateY(100%);
    }
}

@keyframes nav-list-item-expand {
    from {
        opacity: 0;
        transform: translateY(100%) scale(0.2);
    }
}

@keyframes item-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round 0);
    }
}
