@import 'styles/_helpers';

.presentation-entry {
    $this: &;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: $spacing--small $spacing--small $spacing;
    border-radius: $border-radius--large;
    background-color: color(primary, light);
    aspect-ratio: 1/1;

    animation: entry-enter 600ms $ease-in-out-quint--custom backwards;
    @include animation-delay(10, 50ms);

    &:hover,
    &:focus:focus-visible {
        background-color: color(primary);

        #{$this}__tag {
            background-color: color(primary, light);
        }
    }

    &__illustration {
        display: block;
        max-width: min(50%, 10rem);
        margin: auto;
    }

    &__tag {
        @include font-size(small);
        position: absolute;
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }

    &__title {
        @include font-size(sub-title);
        margin: 0;
    }

    &__summary {
        @include font-size(base);
        color: $color-muted;
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
